.lowerfooter {
  /* position: absolute;  */
  height: 5vw;
  /* margin-top:18vw; */
  width: 100%;
  background-color: #030811;
}


.lowerFooterLine {
  position: absolute; 
  width: 85%;
  margin-left: 7.5%;
  height: 0.15vw;
  background: #2a1b68;
}

.lowerFooterCopyright {
  position: absolute;
  color: #9aa5c6;
  font-family: "LSEL";
  font-size: 1vw;
  padding-top: 1.3vw;
}

.lowerFooterLinks {
  position: absolute;
  color: #9aa5c6;
  font-family: "LSEL";
  font-size: 1vw;
  padding-top: 1.3vw;
  margin-left: 70%;
}


@media only screen and (max-width: 1080px) {
  .lowerfooter {
    position: absolute; 
    height: 5vw;
    font-size: 2vw;
  }
}