.s5 {
  margin-top: 5vw;
}

.S5header {
  display: flex;
  justify-content: center; /* Center the text horizontally */
  align-items: center; /* Center the text vertically */
}

.t11, .t12 {
  color: white;
  font-family: "LSB";
  font-size: 4vw;
  line-height: normal;
}

.t11 {
  background-image: linear-gradient(to right, #8dc1ff, #5236ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-right: 1vw;
}


.s5 img{
  height: 18vw;
  margin-top: 8vw;
  margin-left: 17.5vw;

}


.S5header2{
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: solid 1px rgb(208, 0, 255); */
  gap: 6vw;
  margin-left: 1vw;
  margin-top: -9vw;
  font-size: 1vw;
  height: 6vw;
}





.h21{
  color: #9AA5C6;
  font-family: 'LSEL';
  font-size: 1.2vw;
  width: 15vw;
  text-align: left;
}

.h22{
  color: #9AA5C6;
  font-family: 'LSEL';
  font-size: 1.2vw;
  width: 16vw;
  text-align: left;
}

.h23{
 
  color: #9AA5C6;
  font-family: 'LSEL';
  text-align: left;
  font-size: 1.2vw;
  width: 13vw;
  /* margin-left: 1vw; */
  /* border: solid 1px rgb(255, 0, 0); */
}




@media only screen and (max-width: 600px) {
  
  .h23,.h22,.h21{
    font-size: 0.8vw;
  }


}