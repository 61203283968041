.s6 {
  border: solid 1px rgba(255, 0, 119, 0);
  position: relative;
  margin-top: 15vw;
  height: 35vw;
}


.S6header {
  display: flex;
  justify-content: center; /* Center the text horizontally */
  align-items: center; /* Center the text vertically */
}

.s6t11, .s6t12 {
  color: white;
  font-family: "LSB";
  font-size: 4vw;
  line-height: normal;
}

.s6t11 {
  margin-right: 1vw; /* Add some space between the two spans */
}

.s6t12 {
  background-image: linear-gradient(to right, #8dc1ff, #5236ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}


.s6MI1 {
  margin-top: 8vw;
  margin-left: 10vw;
  width: fit-content;
  position: relative;
  z-index: 10;
  /* border: solid 1px rgb(255, 0, 119); */
}

.s6MI1 img {
  /* margin-top: 10vw; */
  backdrop-filter: blur(10px);
  height: 20vw;
}

.s6MI1 h2 {
  position: relative;
  z-index: 11;
  margin-top: -15vw;
  color: white;
  margin-left: 5vw;
  font-size: 2vw;
  font-family: "LSEL";
  width: 40vw;
}

.s6MI1 p {
  position: relative;
  margin-top: 2vw;
  width: 22vw;
  color: #9aa5c6;
  margin-left: 5vw;
  font-size: 1.2vw;
  font-family: "LSEL";
}

.s6MI2 {
  margin-top: -14vw;
  margin-left: 59vw;
  z-index: 10;
  position: relative;
}

.s6MI2 img {
  margin-top: 2vw;
  backdrop-filter: blur(2px);
  height: 20vw;
}

.s6MI2 h2 {
  margin-top: -16vw;
  color: white;
  margin-left: 2vw;
  font-size: 2vw;
  font-family: "LSEL";
  position: relative;
  width: 30vw;
}

.s6MI2 p {
  margin-top: 2vw;
  width: 25vw;
  color: #9aa5c6;
  margin-left: 2vw;
  font-size: 1.2vw;
  font-family: "LSEL";
  position: relative;
}

.s6MI3 {
  position: absolute;
  margin-left: 37vw;
  z-index: 1;
}
.s6MI3 img{
  height: 22vw;
  /* border: solid 1px rgb(0, 255, 4); */
}


.s6MI2I {
  height: 20vw;
  width: 20vw;
}

