.s4 {
  
  /* height:120vw; */
  /* border: solid 1px rgb(208, 0, 255); */
}


.S4header{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -2.5vw;
  margin-top: 4vw;
}

.s4h21 {
  background-image: linear-gradient(to right, #8dc1ff, #5236ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: white;
  font-family: "LSB";
  font-size: 4vw;
  line-height: normal;
  margin-right: 1vw;
}

.s4h22 {
  color: rgb(255, 255, 255);
  font-family: "LSB";
  font-size: 4vw;
  line-height: normal;
}

.s4BA {
  align-self: center;
  justify-content: center;
}

.s4bA h1 {
  color: white;
  font-family: "LSB";
  margin-left: 18vw;
  margin-top: 17vw;
  font-size: 2.5vw;
}

.s4bA p {
  color: #9aa5c6;
  font-family: "LSEL";
  margin-left: 18vw;
  width: 27vw;
  font-size: 1.3vw;
  margin-top: 3vw;
}

.s4bA img {
  height: 25vw;
  margin-top: -15vw;
  margin-left: 55vw;
}


.s4bB {
  align-self: center;
  justify-content: center;
}

.s4bB h1 {
  color: white;
  font-family: "LSB";
  margin-top: 10vw;
  font-size: 2.5vw;
  margin-left: 55vw;
  width: 27vw;
}

.s4bB p {
  color: #9aa5c6;
  font-family: "LSEL";
  width: 27vw;
  font-size: 1.3vw;
  margin-top: 2vw;
  margin-left: 55vw;
}

.s4bB img {
  height: 27vw;
  margin-top: -15vw;
  margin-left: 15vw;
}






.s4BC {
  align-self: center;
  justify-content: center;
}

.s4bC h1 {
  color: white;
  font-family: "LSB";
  margin-left: 20vw;
  margin-top: 12vw;
  font-size: 2.5vw;
}

.s4bC p {
  color: #9aa5c6;
  font-family: "LSEL";
  margin-left: 20vw;
  width: 27vw;
  font-size: 1.3vw;
  margin-top: 2vw;
}

.s4bC img {
  height: 28vw;
  margin-top: -15vw;
  margin-left: 55vw;
}



