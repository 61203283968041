/* Header.css */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vw;
  background-color: transparent;
  /* border: solid 1px rgb(255, 0, 0); */
}

.logo {
  margin-left: 3vw;
  /* border: solid 1px rgb(255, 255, 255); */
  height:3vw;
  width:15vw;
}

.xnav-links {
  /* list-style-type: none; */
  display: flex;
  align-items: center;
  gap: 4vw;
  margin-right: 5vw;
  /* border: solid 1px rgb(255, 0, 0); */
}

.xnav-links li {
  margin-right: 10px;
  transition: transform 0.3s;
  /* border: solid 1px rgb(255, 0, 0); */
}

.xnav-links li:hover{
  transform: scale(1.25);
}

.xnav-links a,
.xnav-links button {
  text-decoration: none;
  background-color: transparent;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 1vw;
  font-family: "LSEL";
  cursor: pointer;
  letter-spacing: 0.24px;
  transition: transform 0.1s;
}


.xnav-links a:hover,
.xnav-links button:hover {
  color: rgb(255, 255, 255);
  transform: scale(1.1);
}

.swapBtn {
  width: 9vw;
  height: 3.5vw;
  border-radius: 3vw;
  background: linear-gradient(90deg, #83bcff 0%, #364aff 100%);
  font-size: 2vw;
}






@media only screen and (max-width: 920px) {
  header {
    flex-wrap: wrap;
    justify-content: center;
    padding: 2vw;
  }
  
  .logo {
    width: 100%;
    height: 7vw;
    margin: 0;
    text-align: center;
    margin-bottom: 2vw;
    padding: 2vw;
  }
  
  .xnav-links {
    flex-basis: 100%;
    justify-content: center;
    margin-right: 0;
    gap: 8vw;
  }
  
  .xnav-links li {
    margin-right: 0;
  }


  .xnav-links a,
  .xnav-links button {
    font-size: 2vw;
  }


  .swapBtn {
    width: 12vw;
    height: 4.5vw;
    font-size: 2.5vw;
  }
}
