.s1 {
  height: 34vw;
  width: fit-content;
  margin-left: 5vw;
  /* border: solid 1px rgb(255, 0, 0); */
}

.sh1 {
  font-family: "LSEL";
  color: #fff;
  font-size: 2.5vw;
  font-style: normal;
  line-height: 131%;
  letter-spacing: 0.05vw;
  margin-top: 4vw;
  margin-left: 10vw;
  width: fit-content;
  /* border: solid 1px rgb(255, 0, 0); */
}

.sh2 {
  width: fit-content;
  font-family: "LSB";
  font-size: 4vw;
  line-height: 4vw;
  margin-top: 1.5vw;
  margin-left: 10vw;
  width: 40vw;
  background-image: linear-gradient(to right, #8dc1ff, #5236ff);
  -webkit-background-clip: text; /* For Safari */
  -webkit-text-fill-color: transparent; /* For Safari */
  background-clip: text;
  color: white; /* Fallback color if gradient is not supported */
  /* border: solid 1px rgb(255, 0, 0); */
  position: relative;
  animation: shuffle 1s pause;
}




@keyframes shuffle {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.sh2:hover {
  animation: shuffle 1s infinite;
}

.sh3 {
  width: fit-content;
  font-family: "LSEL";
  color: #9aa5c6;
  font-size: 1.5vw;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02vw;
  margin-top: 1.5vw;
  margin-left: 10vw;
  /* border: solid 1px rgb(255, 0, 0); */
}

.row {
  width: fit-content;
  margin-top: 1vw;
}

.row button {
  width: 15vw;
  height: 4vw;
  border-radius: 3vw;
  background: linear-gradient(90deg, #83bcff 0%, #364aff 100%);
  color: white;
  font-family: "LSEL";
  font-size: 1.2vw;
  margin-top: 4vw;
  margin-left: 10vw;
  outline: none;
  border: none;
  transition: transform 0.3s;
}

.row button:active {
  opacity: 80%;
}

.row button:hover {
  transform: scale(1.1);
}

.gp {
  position: absolute;
  width: 3.5vw;
  height: 3.5vw;
  margin-top: 4.1vw;
  margin-left: 2vw;
  transition: transform 0.3s;
}

.gp:hover {
  transform: scale(1.1);
}

.gp:active {
  opacity: 80%;
}

.as {
  position: absolute;
  width: 3.5vw;
  height: 3.5vw;
  margin-top: 4.1vw;
  margin-left: 7vw;
  transition: transform 0.3s;
}
.as:active {
  opacity: 80%;
}
.as:hover {
  transform: scale(1.1);
}

@media (max-width: 920px) {
  .s1 {
    height: auto;
    /* border: solid 1px rgb(0, 123, 255); */
    margin-left: 5vw;
  }

  .sh1 {
  }

  .sh2 {
  }

  .sh3 {
  }

  .gp {
    width: 5vw;
    height: 5vw;
    margin-left: 2vw;
  }

  .as {
    width: 5vw;
    height: 5vw;
    margin-left: 10vw;
  }

  .row button {
    width: 20vw;
    height: 6vw;
    font-size: 2vw;
  }
}
