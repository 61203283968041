
*{
  margin: 0;
  padding: 0;
}

.container {
  position:relative;
 
  z-index: 1;
  background: #030811;
  /* border: solid 1px rgb(255, 0, 0); */
}

.main {
  position: relative;
  
}


.bg{
  position:absolute;
  background-size:auto;
  width: 100%;
  /* height: 100%; */
}

.SSP{
  background-color: #080f1d;
}




@font-face {
    font-family: 'LS';
    src: url('./fonts/LeagueSpartan-Regular.46f6b0819a58b88544ae.ttf') format('truetype');
  }
  

@font-face {
    font-family: 'LSL';
    src: url('./fonts/LeagueSpartan-Light.60508c02459790820022.ttf') format('truetype');
  }

  @font-face {
    font-family: 'LSEL';
    src: url('./fonts/LeagueSpartan-ExtraLight.ttf') format('truetype');
  }


  @font-face {
    font-family: 'LSB';
    src: url('./fonts/LeagueSpartan-SemiBold.ttf') format('truetype');
  }