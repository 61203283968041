.sp {
  position: relative;
  background: radial-gradient(#2f0b88, #180b38, #060a12);
  height: 100%;
  border: solid 1px #6578f500;
}

.sp_panel {
  border: solid 1px #6578f5;
  border-radius: 20px;
  height: 700px;
  width: 400px;
  margin-top: 70px;
  margin-left: 40vw;
  margin-bottom: 200px;
  align-items: center;
  background-color: #1c1c26;
}

.sp_panel h1 {
  font-family: "LSEL";
  color: #94aaba;
  font-size: 20px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.sp_panel h3 {
  font-family: "LSEL";
  color: #ffffff;
  font-size: 0.5vw;
  width: 70%;
  margin-left: 15%;
  text-align: center;
  margin-top: 20px;
  /* border: solid 1px red; */
}

.sp_line {
  border-top: 1px solid #393e4c;
  margin: 1vw 0;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5%;
}

.IFS {
  margin-top: 20px;
  width: 70%;
  padding-right: 15px;
  height: 10%;
  margin-left: 14%;
  border-radius: 15px;
  border: 0vw solid #3949b6;
  background: #272732;
  text-align: right;
  font-size: 20px;
  font-family: "LS";
  color: #94aaba;
  letter-spacing: 2.621px;
}

.IFS2 {
  margin-top: 20px;
  width: 70%;
  padding-right: 15px;
  height: 10%;
  margin-left: 14%;
  border-radius: 15px;
  border: none;
  background: #272732;
  text-align: right;
  font-size: 20px;
  font-family: "LS";
  color: #94aaba;
  letter-spacing: 2.621px;
}

.IFS:focus,
.IFS2:focus {
  outline: none;
  border: solid 1px #3949b6;
}

.sp_NB {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.sp_NB img {
  height: 30px;
  width: 20px;
  transition: transform 0.3s ease;
  width: fit-content;
  margin-top: 50px;
  /* border: solid 1px white; */
}

.sp_NB img.rotated {
  transform: rotate(180deg);
}

.sp_cas {
  /* border: solid 1px white; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15%;
}

.sp_cas button {
  border-radius: 40px;
  background: linear-gradient(90deg, #83bcff 0%, #364aff 100%);
  height: 70px;
  width: 70%;

  color: white;
  font-size: 20px;
  font-family: "LS";
  border: none;
}

.connect-button {
  position: relative;
  /* padding: 10px 20px; */
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.connect-button.loading {
  background-color: #ccc;
  cursor: not-allowed;
}

.loading-spinner {
  position: absolute;
  margin-top: -140px;
  z-index: 111;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 5px solid #015bc3;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1200px) {
  .sp_panel {
    width: 400px;
    margin-left: 30%;
  }

  .sp_panel h3 {
    font-size: 1vw;
  }
}

@media only screen and (max-width: 800px) {
  .sp_panel {
    width: 400px;
    margin-left: 20%;
  }
  .sp_panel h3 {
    font-size: 1.5vw;
  }
  .loading-spinner {
    height: 3vw;
    width: 3vw;
  }
}
@media only screen and (max-width: 500px) {
  .sp_panel {
    width: 90%;
    margin-left: 5%;
  }

  .loading-spinner {
    height: 3vw;
    width: 3vw;
  }
}
