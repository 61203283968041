.footer {
  width: 100%;
}

.fb1 {
  position: absolute;
  width: 30%;
  height: 17vw;
  margin-left: 1.665%;
  margin-top: -8vw;
}

.fb1 img {
  margin-top: 6.5vw;
  margin-left: 7vw;
  height: 5vw;
}

.fb1 h3 {
  color: #9aa5c6;
  width: 15vw;
  margin-top: 2vw;
  margin-left: 7vw;
  font-size: 1vw;
  font-family: "LSEL";
}

.fb2 {
  position: absolute;
  width: 30%;
  height: 20vw;
  margin-left: 35%;
}

.fb3 {
  width: 30%;
  height: 20vw;
  margin-left: 68.335%;
}

.fb3 h1 {
  color: #ffffff;
  margin-top: 10vw;
  margin-left: 75px;
  font-size: 20px;
  font-family: "LSEL";
}

.fb3 p {
  color: #9aa5c6;
  width: 15vw;
  margin-top: 40px;
  margin-left: 75px;
  font-size: 20px;
  font-family: "LSEL";
}

@media only screen and (max-width: 1080px) {
  .fb1 {
    width: 100%;
    position: relative;
    margin-left: 0;
    margin-top: 3vw;
  }
  .fb1 img{
    margin-left: 0;
    width: 100%;
  }
  .fb1 h3{
    text-align: center;
    width: 100%;
    margin-left: 0;
  }

  .fb2 {
    width: 100%;
    position: relative;
    margin-left: 0;
    margin-top: -15vw;
  }

  .fb3 {
    width: 100%;
    margin-left: 0;
  }

  .fb3 h1 {
    margin-top: 5vw;
    width: 100%;
    margin-left: 0;
    text-align: center;
    font-size: 3vw;
  }
  .fb3 p {
    font-size: 2vw;
    margin-top: 1vw;
    width: 100%;
    margin-left: 0;
    text-align: center;
  }
}
