.tv-page {
    background-color: #0c0f2d;
    color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.tv-container {
    margin: 0 auto;
    padding: 20px;
    max-width: 1200px;
}

.tv-header {
    display: flex;
    justify-content: flex-start;
    padding: 20px 0;
}

.tv-logo {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 1.5px;
}

.tv-content {
    text-align: center;
    margin-top: 50px;
}

.tv-content h1 {
    font-size: 48px;
    margin-bottom: 20px;
}

.tv-content p {
    font-family: 'LSEL';
    font-size: 18px;
    line-height: 1.8;
    max-width: 800px;
    margin: 0 auto 20px;
}

.tv-content a {
    font-size: 21px;
    color: #fafafa; /* Bright color for visibility */
    text-decoration: underline; /* Underline for emphasis */
    transition: all 0.5s;
    color: hwb(159 0% 0%)
}

.tv-content a:hover {
    color: hwb(159 1% 16%);
    font-size: 22px;
}


