.dropdown {
  width: 70%;
  display: inline-block;
  margin-left: 15%;
  margin-top: 15px;
}

.dropdown-header {
  display: flex;
  align-items: center;
}

.dropdown-image {
  width: 30px;
  height: 30px;
}

.dropdown-text {
  color: white;
  font-family: "LS";
  margin-left: 10px;
  font-size: 20px;
}

.dropdown-button {
  background-color: rgba(221, 221, 221, 0);
  border: none;
  color: rgb(255, 255, 255);
  padding: 5px 5px;
  cursor: pointer;
  position: absolute;
  margin-top: -30px;
  margin-left: 90px;
}

.dropdown-menu {
  position: absolute;
  width: 10%;
  background-color: rgba(0, 0, 0, 0.919);
  border-radius: 15px;
  list-style: none;
  padding: 3px;
  margin: 0;
  display: none;
}

.dropdown-menu li {
  padding: 10px;
  cursor: pointer;
  color: white;
  font-family: "LSEL";
}
.dropdown-menu li:hover {
  color: #94aaba;
}
.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown2 {
  width: 70%;
  display: inline-block;
  margin-left: 15%;
  margin-top: 35px;
}

.dropdown-header2 {
  display: flex;
  align-items: center;
}

.dropdown-image2 {
  width: 30px;
  height: 30px;
}

.dropdown-text2 {
  color: white;
  font-family: "LS";
  margin-left: 10px;
  font-size: 20px;
}

.dropdown-button2 {
  background-color: rgba(221, 221, 221, 0);
  border: none;
  color: rgb(255, 255, 255);
  padding: 5px 5px;
  cursor: pointer;
  position: absolute;
  margin-top: -30px;
  margin-left: 90px;
}

.dropdown-menu2 {
  position: absolute;
  width: 10%;
  background-color: rgba(0, 0, 0, 0.919);
  border-radius: 15px;
  list-style: none;
  padding: 3px;
  margin: 0;
  display: none;
}

.dropdown-menu2 li {
  padding: 10px;
  cursor: pointer;
  color: white;
  font-family: "LSEL";
}
.dropdown-menu2 li:hover {
  color: #94aaba;
}

.dropdown2:hover .dropdown-menu2 {
  display: block;
}
