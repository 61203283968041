.s3 {

  /* border: solid 1px rgb(0, 255, 238); */
  height: 45vw;
}

.s3h2 {
  position: absolute;
  background-image: linear-gradient(to right, #8dc1ff, #5236ff);
  -webkit-background-clip: text; 
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: white; 
  font-family: "LSB";
  font-size: 4vw;
  line-height: normal;
  margin-top: 4vw;
  margin-left: 32vw;
}

.s3block1 {
  position:absolute;
  width: 15vw;
  height: 20vw;
  flex-shrink: 0;
  border-radius: 1.2vw;
  border: 1px solid #2a1b68;
  background: rgba(74, 61, 140, 0.1);
  backdrop-filter: blur(13px);
  margin-top: 14vw;
  margin-left: 15vw;
  border: 1.5px solid rgba(108, 88, 174, 0.42);
  transition: transform 0.3s;
}

.s3block1:hover,.s3block2:hover,.s3block3:hover{
  transform: scale(1.1);
}

.s3block2 {
  position:absolute;
  width: 15vw;
  height: 20vw;
  flex-shrink: 0;
  border-radius: 1.2vw;
  border: 1px solid #2a1b68;
  background: rgba(74, 61, 140, 0.1);
  backdrop-filter: blur(13px);
  margin-top: 18vw;
  margin-left: 40vw;
  border: 1.5px solid rgba(108, 88, 174, 0.42);
  transition: transform 0.3s;
}

.s3block3 {
  position:absolute;
  width: 15vw;
  height: 20vw;
  flex-shrink: 0;
  border-radius: 1.2vw;
  border: 1.5px solid rgba(108, 88, 174, 0.42);
  background: rgba(74, 61, 140, 0.1);
  backdrop-filter: blur(13px);
  margin-top: 14vw;
  margin-left: 65vw;
  /* border: solid 1px rgb(52, 57, 134); */
  transition: transform 0.3s;
}





.s3mi{
 height: 6vw;
  margin-top: 2vw;
  margin-left: 4vw;
  /* border: solid 1px red; */
}

.s3misign{
  position:absolute;
  margin-top: 1.5vw;
  margin-left: -7vw;
}


.s3title{
  width:10vw;
  margin-top: 2vw;
  margin-left: 2vw;
  color: white;
  font-family: 'LSEL';
  font-size: 1vw;
  transition: transform 0.3s, text-shadow 0.3s;
}

.s3p{
  margin-top: 1vw;
  width:12vw;
  margin-left: 2vw;
  font-size: 0.9vw;
  color: #9AA5C6;
  font-family: 'LSEL';
  /* border: solid 1px rgb(208, 0, 255); */
  transition: transform 0.3s, text-shadow 0.3s;
}

.s3p:hover,.s3title:hover{
  transform: scale(1.1);
  text-shadow: 0px 5px 0px rgba(0, 0, 0, 1);
  /* color: white; */
}