.s2 {
  height: 35vw;
  /* border: solid 1px rgb(0, 123, 255); */
}

.laptop {
  margin-left: 10vw;
  height: 30vw;
}

.S2header{
  position: absolute;
  margin-top: -30vw;
  margin-left: 48vw;
  /* border: solid 1px rgb(0, 123, 255); */
  display: flex;
  justify-content: center;
  align-items: center;
}


.t1 {
  color: #fff;
  font-family: "LSB";
  font-size: 4vw;
  line-height: normal;
  margin-right: 1vw;
}

.t2 {
  background-image: linear-gradient(to right, #8dc1ff, #5236ff);
  -webkit-background-clip: text; /* For Safari */
  -webkit-text-fill-color: transparent; /* For Safari */
  background-clip: text;
  color: white; 
  font-family: "LSB";
  font-size: 4vw;
  line-height: normal;
}

.s2p{
    position:absolute;
    color: #9AA5C6;
    font-family: "LSEL";
    font-size: 2vw;
    width: 35vw;
    margin-left: 48vw;
    margin-top: -22vw;
}



@media (max-width: 768px) {
    .s2 {
      height: auto;
      margin-top: 8vw;
    }
  }