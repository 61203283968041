.quick-links-container {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 2vw;
  margin-top: 5vw;
  /* margin-left: 10vw; */
}

.column {
  flex: 1;
}

.column2 {
  flex: 1;
  margin-top: 3vw;
}

.column-heading {
  font-size: 1.3vw;
  margin-bottom: 2vw;
  color: aliceblue;
  font-family: "LSEL";
}

.link-list {
  list-style: none;
  padding: 0;
  font-family: "LSEL";
  /* border: solid 1px rgb(0, 30, 255); */
}

.link-list li {
  margin-bottom: 2vw;
  transition: transform 0.3s;
  /* border: solid 1px red; */
  width: fit-content;
}
.link-list li:hover {
  transform: scale(1.25);
}

.link-list li a {
  color: #9aa5c6;
  text-decoration: none;
}



.link-list li a:hover {
  color: #9aa5c6;
}

@media only screen and (max-width: 1080px) {
  .quick-links-container {
    width: auto;
    margin-top: 0;
    text-align: center;
    font-size: 2vw;
    height: 13vw;
  }

.column-heading {
  font-size: 2vw;
  margin-bottom: 2vw;
}
  .column2 {
    flex: 1;
    margin-top: 5vw;
  }

  .link-list li {
    margin-bottom: 1.5vw;
  }
  .column {
    margin-top: 2vw;
    flex: 1;
  }
}
